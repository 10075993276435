.image-block__video-overlay {
  &:hover svg,
  &:focus svg {
    color: rgba($drexel-gold, 0.7);
  }
}

// image-block.scss

// base
.image-block {
  @include component-normalize();

  .zoom-button {
    &:hover,
    &:focus {
      color: $drexel-gold;

      &:before,
      &:after {
        background-color: $drexel-gold;
      }
    }
  }

  &.image-block--pale-image .zoom-button {
    color: $drexel-navy;

    &:before,
    &:after {
      background-color: $drexel-navy;
    }

    &:hover,
    &:focus {
      color: $drexel-gold;

      &:before,
      &:after {
        background-color: $drexel-gold;
      }
    }
  }
}
