.simple-accordion:has(+ .simple-accordion) {
  border-bottom-color: $drexel-grey-soft;
}

.simple-accordion__plus-sign {
  &::after,
  &::before {
    background-color: $drexel-blue;
  }
}

// Secondary variant (seen in Area of Study)
.simple-accordion--secondary {
  .simple-accordion__trigger {
    color: $drexel-blue;
  }
}
