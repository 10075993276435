.footer-info {
  background-color: $drexel-blue-dark;
}

.footer-info__link {
  border-color: rgba($white, 0.3);
}

.footer-info__label {
  color: $drexel-blue-pale;
}
