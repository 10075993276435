.article-title-card__dateline {
  color: $drexel-grey-light;
}

.article-title-card__date,
.article-title-card__info {
  &:before {
    background-color: $drexel-grey-soft;
  }
}
