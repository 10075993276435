a.search-button-bar__link {
  &:hover,
  &:focus {
    background-color: $drexel-blue;
    color: $white;
  }

  &.is-active {
    background-color: $drexel-navy;
    color: $white;
  }
}
