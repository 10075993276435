.quote-promo {
  &.quote-promo--overlap-right {
    &:before {
      background-color: $drexel-gold;
    }
  }
}

.quote-promo__attribution {
  color: $drexel-grey-light;
}
