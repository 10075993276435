.go-back {
  border-bottom-color: $drexel-grey-soft;

  a {
    color: $drexel-blue;

    &:hover,
    &:focus {
      color: $drexel-navy;

      svg {
        color: $drexel-navy;
      }
    }

    svg {
      color: $drexel-gold;
    }
  }
}
