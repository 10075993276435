.date-quick-pick label {
  color: $drexel-blue;
}

.date-quick-pick input:checked + label {
  background-color: $drexel-navy;
  color: $white;

  .date-quick-pick__check {
    color: $drexel-blue-light;
  }

  .search-listing__facets & {
    &:hover,
    &:focus {
      color: $drexel-blue-light;
    }
  }
}

.date-quick-pick input:focus + label,
.date-quick-pick label:hover {
  background-color: $drexel-blue;
  color: $white;

  .search-listing__facets & {
    background-color: transparent;
    color: $drexel-grey-text;
  }
}
