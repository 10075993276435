.stat-icon-card {
  background-color: $base-background-color;
  color: $drexel-navy;
}

.stat-icon-card--yellow {
  background-color: $drexel-gold;
  color: $drexel-navy;

  .stat-icon-card__header::after {
    background-color: $drexel-navy;
  }

  .stat-icon-card__link {
    color: inherit;
  }
}

.stat-icon-card--gray {
  color: $drexel-navy;
  background-color: $drexel-grey-pale;

  .stat-icon-card__description {
    color: $black;
  }
}

.stat-icon-card--blue {
  background-color: $drexel-navy;
  color: $white;

  .stat-icon-card__link {
    color: inherit;
  }
}
