.color-block-promo--blue,
.color-block-promo--navy {
  color: white;

  &.clickable-card--whole-card:hover a,
  a:hover,
  a:focus {
    border-bottom-color: rgba($white, 0.5);
  }

  .color-block-promo__title {
    color: $white;
  }
}

.color-block-promo--gold,
.color-block-promo--pale-grey {
  color: $black;

  &.clickable-card--whole-card:hover a,
  a:hover,
  a:focus {
    border-bottom-color: rgba($drexel-grey-text, 0.5);
  }

  .color-block-promo__title {
    color: $black;
  }
}

.color-block-promo--blue {
  background-color: $drexel-blue;
}

.color-block-promo--gold {
  background-color: $drexel-gold;
}

.color-block-promo--navy {
  background-color: $drexel-navy;
}

.color-block-promo--pale-grey {
  background-color: $drexel-grey-pale;
}

.color-block-promo__image-wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background-color: transparent;
  transition: background-color $standard-transition;

  .clickable-card--whole-card:hover & {
    background-color: $drexel-gold;
  }
}
