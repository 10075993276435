.event-facets {
  background-color: $drexel-grey-pale;
  color: $drexel-grey-medium;

  h3,
  h4 {
    color: $black;
  }

  @media (min-width: $bp-min-medium) {
    .program-listing__facets &,
    .staff-search-listing__facets & {
      background-color: transparent;

      h3 {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        overflow: hidden;
        height: 1px;
        width: 1px;
        word-wrap: normal;
      }
    }

    .radio-facets__group-heading h3 {
      position: static !important;
      clip: auto;
      overflow: auto;
      height: auto;
      width: auto;
    }
  }
}

.event-facets__group {
  border-bottom-color: $drexel-grey-soft;
}

.event-facets__group-clear {
  color: $drexel-blue;
}

.event-facets__group-values {
  label {
    svg {
      color: $drexel-blue-light;
    }
  }

  input:checked + label:before {
    border-color: $drexel-blue-light;
  }
}
