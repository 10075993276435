.responsive-table__divider {
  color: $drexel-gold;
}

.responsive-table__button--clear-all {
  color: $drexel-blue;
}

// Non-table layout for mobile
@media screen and (max-width: $bp-min-small), print and (max-width: 5in) {
  .responsive-table th[role="rowheader"] {
    color: $drexel-blue;
  }
}
