.event-promo__body {
  .event-promo--uplap & {
    background-color: white;
  }
}

.event-promo__date-card {
  color: $drexel-grey-light;

  &:before {
    background-color: $drexel-gold;
  }
}

.event-promo__title {
  color: $black;

  a {
    color: inherit;

    .clickable-card--whole-card:hover &,
    &:hover,
    &:focus {
      border-bottom-color: rgba($black, 0.5);
    }
  }
}
