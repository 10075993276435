.share__trigger {
  color: $drexel-blue-light;

  &:hover,
  &:focus {
    color: $drexel-navy;
  }
}

.share__btn-icon {
  background-color: $drexel-blue-light;

  .share__btn:hover &,
  .share__btn:focus & {
    background-color: $drexel-navy;
  }
}

.share__btn-text {
  color: $drexel-grey-text;

  .share__btn:hover &,
  .share__btn:focus & {
    color: $drexel-navy;
  }
}
