@import "../../base/_stacking-context.scss";

.btn--primary {
  border-color: $drexel-gold;
  color: $black;
  position: relative;
  z-index: $stack-internal;

  &:before {
    background: linear-gradient(
      135deg,
      $drexel-gold 0%,
      $drexel-orange-light 100%
    );
    content: "";
    display: block;
    position: absolute;
    top: -2px; // Allow for border
    right: -2px;
    bottom: -2px;
    left: -2px;
    opacity: 0;
    z-index: $stack-back;
    transition: opacity 0.3s;
  }

  &:active,
  &:hover,
  &:focus {
    color: $black;
    border-color: $drexel-gold;

    &:before {
      opacity: 1;
    }
  }
}

.btn--primary.btn--primary-pickup {
  color: currentColor;

  &:active,
  &:hover,
  &:focus {
    color: $black;
  }
}

.btn--secondary,
.btn--taxonomy,
.btn--tertiary {
  background-color: $drexel-navy;
  border-color: $drexel-navy; /* just to keep the size the same as other buttons */
  color: $white;
  position: relative;
  z-index: $stack-internal;

  &:before {
    background: linear-gradient(
      135deg,
      $drexel-gold 0%,
      $drexel-orange-light 100%
    );
    content: "";
    display: block;
    height: calc(100% + 4px); /* Allow for border */
    position: absolute;
    top: -2px; /* Allow for border */
    left: -2px; /* Allow for border */
    opacity: 0;
    width: calc(100% + 4px); /* Allow for border */
    z-index: $stack-back;
    transition: opacity 0.3s;
  }

  &:active,
  &:hover,
  &:focus {
    color: $black;

    &:before {
      opacity: 1;
    }
  }
}

.btn--taxonomy,
.btn--tertiary {
  background-color: $drexel-blue;
  border-color: $drexel-blue;
}

.btn--link {
  color: $drexel-blue;

  &.is-disabled {
    color: $drexel-grey-soft;
    cursor: default;

    &:hover,
    &:focus {
      color: $drexel-grey-soft;
    }
  }

  &.is-current {
    color: $black;

    &:hover,
    &:focus {
      color: $black;
    }
  }

  &:hover,
  &:focus {
    color: $drexel-gold;
  }
}
