.search-results__alert-banner-flag {
  background-color: $drexel-navy;
  color: $white;
}

.search-results__relevance-header {
  span {
    border-bottom-color: $drexel-gold;
  }
}

.search-results__sorter {
  border-left-color: $drexel-grey-soft;

  &.is-active {
    .search-results__sort-label {
      border-bottom-color: $drexel-gold;
      color: $black;

      &:hover,
      &:focus {
        border-bottom-color: $drexel-navy;
      }
    }
  }
}

.search-results__sort-label {
  &:hover,
  &:focus {
    border-bottom-color: $drexel-navy;
  }
}
