.static-footer {
  background-color: $drexel-navy;
  color: $white;

  a {
    color: $white;

    &:hover,
    &:focus {
      color: $drexel-gold;
    }
  }
}

.static-footer__col {
  border-color: rgba($white, 0.3);
}
