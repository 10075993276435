.more-like-this__title {
  border-bottom-color: $drexel-gold;
}

.more-like-this__item {
  border-bottom-color: $drexel-grey-soft;

  a {
    color: $black;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
      color: $drexel-navy;
    }
  }
}

.more-like-this--boxed-light {
  background-color: $drexel-grey-soft;

  .more-like-this__title {
    border-bottom-color: $drexel-navy-light;
  }
}

.more-like-this--boxed-dark {
  background-color: $drexel-navy-light;
  color: $white;

  .more-like-this__title {
    border-bottom-color: $drexel-gold;
  }

  .more-like-this__item {
    a {
      color: $white;

      &:hover,
      &:focus {
        border-bottom-color: currentColor;
        color: $white;
      }
    }
  }
}
