.promo-slider {
  .slick-prev,
  .slick-next {
    color: $drexel-blue-light;

    &:hover,
    &:focus {
      color: $drexel-blue;
    }
  }
}
