.tour-stop__video {
  button {
    color: $drexel-navy;

    &:hover,
    &:focus {

      .tour-stop__button-text {
        border-bottom-color: $drexel-navy;
      }


      .tour-stop__play {
        border-color: $drexel-blue;
      }

      .tour-stop__play:after,
      .tour-stop__play:before,
      .tour-stop__play .tour-stop__line {
        background-color: $drexel-blue;
      }

      .tour-stop__play .tour-stop__triangle {
        border-color: transparent transparent transparent $drexel-blue;
      }
    }
  }

  svg {
    color: $drexel-blue-light;
  }
}


/* Animated Play Button */
.tour-stop__play {
  border-color: $drexel-blue-light;
}

.tour-stop__play:after,
.tour-stop__play:before,
.tour-stop__play .tour-stop__line {
  background-color: $drexel-blue-light;
}

.tour-stop__play .tour-stop__triangle {
  border-color: transparent transparent transparent $drexel-blue-light;
}
