.footer-links {
  border-bottom-color: rgba($white, 0.3);
}

.footer-links__link.footer-links__link--highlight a {
  border-color: $drexel-gold;
  color: $drexel-gold;

  &:hover,
  &:focus {
    border-color: $white;
    color: $white;
  }
}
