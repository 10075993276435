@include create-gradient-variants(
  $core-theme-gradient-colors,
  "mobile-striper",
  0.7,
  135deg,
  "true"
);

.text-columns > li::before {
  background-color: $drexel-blue;
}
