.search-box__input {
  button {
    color: $drexel-navy;

    &:focus-visible,
    &:focus {
      color: $drexel-blue;
      outline: 1px dotted $drexel-blue;
      outline-offset: -1px;
    }
  }

  label {
    color: $drexel-grey-text;
  }

  button:focus ~ label,
  input:focus ~ label {
    color: $white;

    .container & {
      color: $drexel-grey-text;
    }
  }
}

.search-box__clear {
  &:hover,
  &:focus {
    color: $drexel-blue;
  }
}
