@include create-gradient-variants(
  $core-theme-gradient-colors,
  "color-stripe-promo__image-wrapper",
  0.7,
  -45deg,
  "true"
);

.color-stripe-promo__video-icon {
  color: $white;
}

.color-stripe-promo__link {
  a {
    color: $drexel-blue;

    &:hover,
    &:focus {
      border-bottom-color: rgba($drexel-blue, 0.5);
    }
  }
}
