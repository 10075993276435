.stat-card {
  background-color: $drexel-navy;
  color: $white;

  --link-color: #{$drexel-blue-light};
  --link-hover-color: #{$white};
  --link-border: #{rgba($drexel-blue-light, 0.5)};
  --link-hover-border: #{rgba($white, 0.5)};
}

.stat-card--yellow {
  background-color: $drexel-gold;
  color: $drexel-navy;

  --link-color: #{$base-link-color};
  --link-hover-color: #{$drexel-blue-dark};
  --link-border: #{rgba($base-link-color, 0.5)};
  --link-hover-border: #{$drexel-blue-dark};
}

.stat-card--gray {
  color: $drexel-navy;
  background-color: $drexel-grey-pale;

  --link-color: #{$base-link-color};
  --link-hover-color: #{$drexel-blue-dark};
  --link-border: #{rgba($base-link-color, 0.5)};
  --link-hover-border: #{$drexel-blue-dark};
}

.stat-card--white {
  color: $drexel-navy;
  background-color: $white;

  --link-color: #{$base-link-color};
  --link-hover-color: #{$drexel-blue-dark};
  --link-border: #{rgba($base-link-color, 0.5)};
  --link-hover-border: #{$drexel-blue-dark};
}
