.secondary-navigation {
  border-color: $drexel-grey-soft;

  a {
    color: $drexel-grey-text;
    transition: all $standard-transition;
    
    &:hover,
    &:focus,
    &.is-active {
      border-bottom-color: $drexel-gold;
      transition: all $standard-transition;
    }
  }
}


.secondary-navigation__mobile-trigger {
  &:focus,
  &:hover {
    color: $drexel-blue;

    &:after {
      border-bottom-color: $drexel-gold;
    }
  }
}
