@include create-gradient-variants($core-theme-gradient-colors, "bleed-hero", 0.7, -45deg, "true");

@include create-gradient-variants($core-theme-gradient-colors, "bleed-hero--no-image.bleed-hero", 1, -45deg, "true");

@include create-gradient-variants($core-theme-gradient-colors, "bleed-hero__image", 1, -45deg, "false");



.bleed-hero__button {
  background-color: $drexel-navy;
  color: $white;

  &:hover,
  &:focus {
    background-color: $drexel-blue;
  }
}

.bleed-hero__title,
.bleed-hero__subtitle {
  background-color: $white;
  color: $black;
}

/* Animated Play Button */
.bleed-hero__play {
  background-color: $drexel-blue-light;
}

.bleed-hero__play:after,
.bleed-hero__play:before,
.bleed-hero__play .bleed-hero__line {
  background-color: $drexel-navy;

  .bleed-hero__button:hover &,
  .bleed-hero__button:focus & {
    background-color: $drexel-blue;
  }
}

.bleed-hero__play .bleed-hero__triangle {
  border-color: transparent transparent transparent $drexel-navy;

  .bleed-hero__button:hover &,
  .bleed-hero__button:focus & {
    border-color: transparent transparent transparent $drexel-blue;
  }
}
