.header {
  background-image: linear-gradient(135deg, $drexel-navy 0%, $drexel-blue 100%);
  color: $white;

  a,
  button {
    color: $white;

    &:hover,
    &:focus {
      color: $drexel-gold;
    }

    @media print {
      color: $drexel-blue-dark !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.header__primary-navigation {
  background-color: $drexel-navy;

  @media (min-width: $bp-min-small-tablet) {
    color: $black;
    background-color: $white;
    border-bottom-color: rgba($drexel-grey-text, 0.2);
    

    .hiddenNavOnLoad {
        a, button, li, &.primary-nav__top-level-item, span {
        color: white;
      }
    }

    a,
    button,
    li {
     color: $black;

      &:hover,
      &:focus {
        color: $drexel-blue;
      }
    }
  }
}

.header button.header__primary-navigation-close {
  color: $drexel-blue-light;

  &:hover,
  &:focus-visible {
    color: $white;
  }

  @media (max-width: $bp-min-small-tablet) {
    &.touch-focus {
      outline: none;
      box-shadow: none;
    }
  } 
}
