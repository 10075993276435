.event-list-item {
  border-bottom-color: rgba($drexel-grey-soft, 0.5);
  color: $drexel-grey-text;
}

.event-list-item__date,
.event-list-item__time {
  color: $drexel-grey-medium;
}

.event-list-item__date {
  border-right-color: $drexel-gold;
}

.event-list-item__full {
  background-color: $drexel-grey-light;
  color: $white;
}

.event-list-item__title a {
  color: $black;
}
