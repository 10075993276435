.slideshow__nav {
  & > * {
    color: $drexel-blue-light;
  }

  .slick-next,
  .slick-prev {
    &:hover,
    &:focus {
      color: $drexel-navy;
    }

    &.slick-disabled {
      color: $drexel-grey-soft;
    }
  }

  .slick-dots {
    button {
      background-color: $drexel-grey-soft;

      &:hover,
      &:focus {
        background-color: $drexel-navy;
      }
    }

    .slick-active button {
      background-color: $drexel-gold;

      &:hover,
      &:focus {
        background-color: $drexel-navy;
      }
    }
  }
}
