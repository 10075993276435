.sticky-footer {
  background-color: $drexel-blue;
  color: $white;

  @media(min-width: $bp-footer-min-xsmall) {
    white-space: nowrap;
  }

  @media(min-width: $bp-footer-min-medium) {
    background-color: $drexel-blue-dark;
  }

  a {
    color: $white;

    &:hover {
      color: $drexel-gold;
    }
  }
}

.sticky-footer__buttons {
  background-color: $drexel-navy;

  .icon-link {
    background-color: $drexel-blue-dark-dull;

    @media(min-width: $bp-footer-min-small) {
      background-color: $drexel-navy;
      border-color: $drexel-blue-dark;

      &:hover,
      &:focus {
        background-color: $drexel-blue;
        border-color: $drexel-navy;
        color: $white;
      }
    }
  }

  .icon-link__icon {
    color: $white;
  }

  a:hover .icon-link__icon,
  a:focus .icon-link__icon {
    color: $white;
  }

}

.sticky-footer__give {
  .icon-link {
    background-color: $drexel-gold;
    border-color: transparent;
    color: $drexel-navy;

    &:hover,
    &:focus {
      background-color: $drexel-blue;
      border-color: transparent;
      color: $white;
      border-left-width: 1px;
      border-left-color: #6cace4;
      border-left-style: solid;

      @media(min-width: $bp-footer-min-small) {
        border-left-color: #043963;
      }
    }
  }

  .icon-link__icon {
    color: $drexel-navy;
  }

  a:hover .icon-link__icon,
  a:focus .icon-link__icon {
    color: $white;
  }
}

.sticky-footer__menu-close {
  background-color: transparent;
  color: $drexel-blue-light;

  &:hover,
  &:focus {
    color: $drexel-gold;
  }
}

.sticky-footer__connect {
  background-color: $drexel-blue;
  border-right-color: $drexel-blue-light;

  .icon-link__icon {
    color: $white;
  }

  a:hover .icon-link__icon,
  a:focus .icon-link__icon {
    color: $drexel-gold;
  }
}

.sticky-footer__menu-trigger {
  background-color: $drexel-blue;
  color: $white;

  &:hover,
  &:focus {
    color: $drexel-gold;
  }

  &.is-open {
    background-color: $drexel-navy;
  }
}

.sticky-footer__menu {
  background-color: $drexel-blue-dark;
}
