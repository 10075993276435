.news-listing__item {
  border-bottom-color: $drexel-grey-soft;
}

.news-listing__item-dateline {
  color: $drexel-grey-light;
}


.news-listing__item-date {
  .news-listing__item-source + & {
    border-left-color: $drexel-grey-soft;
  }
}
