.experience-grid__title {
  &:before {
    background-color: $drexel-gold;
  }
}

.experience-grid__top-promo-wrapper {
  &:hover:after,
  &:focus:after {
    background-color: $drexel-gold;
  }
}

a.experience-grid__image {
  &:hover:after,
  &:focus:after {
    background-color: $drexel-gold;
  }
}
