.in-the-news {
  background-color: $white;
}

.in-the-news__title {
  border-bottom-color: $drexel-gold;
}

.in-the-news__link {
  border-left-color: $drexel-grey-soft;

  a:hover,
  a:focus {
    border-bottom-color: $drexel-navy;
  }
}
