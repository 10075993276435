$page-title-gradient-colors: $core-theme-gradient-colors;

// Make gradient options from color list for page-title at 70% opacity, -45 degrees,
//   and applied to the after pseudo element
@include create-gradient-variants($page-title-gradient-colors, "page-title", 0.7, -45deg, "true");

.page-title {
  &.page-title--with-image {
    
    h1 span {
      &:before {
        background-color: $drexel-gold;
      }
    }
  }
}
