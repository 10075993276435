/* This overrides a lot of react-select styles, hence the sometimes convoluted selectors and extreme nesting */
.event-typeahead {
  border-bottom-color: $drexel-grey-soft;
  
  .event-typeahead__multi-value {
    border-color: $drexel-grey-soft;
  }

  /* This is bad BEM, but it's on the react-select library, so we're stuck with it */
  .event-typeahead__multi-value__remove {
    background-color: $drexel-blue-light;

    &:hover {
      background-color: $drexel-blue;
    }
  }

  .event-typeahead__control {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPGRlZnM+CiAgICA8cGF0aCBpZD0ic2VhcmNoLWEiIGQ9Ik0xMy43ODgzMjUxLDE2LjQ1MjIxOTkgQzEyLjYxODA2NzcsMTcuMTU2MTkxNiAxMS4yNDc0Mjg2LDE3LjU2MTA3NjIgOS43ODIyMzQ0NiwxNy41NjEwNzYyIEM1LjQ4Mzc5MDI0LDE3LjU2MTA3NjIgMiwxNC4wNzc4NjY1IDIsOS43ODA1MzgxMiBDMiw1LjQ4Mjc1NDQyIDUuNDgzNTY3MDEsMiA5Ljc4MjIzNDQ2LDIgTDkuNzgyMjM0NDYsMi43NDkyMTYwNiBMOS43ODIyMzQ0NiwyIEMxNC4wODAxNjUzLDIgMTcuNTY0MTI5Nyw1LjQ4MzAzNTU3IDE3LjU2NDEyOTcsOS43ODA1MzgxMiBDMTcuNTY0MTI5NywxMS41MzE4ODM4IDE2Ljk4NTM2MSwxMy4xNDgwODczIDE2LjAwODYzNDYsMTQuNDQ4Mzg3IEMxNi4wMjExNzUxLDE0LjQ2MDYyNTEgMTYuMDMzNTUxMiwxNC40NzMxMjYxIDE2LjA0NTc1NTcsMTQuNDg1ODg5NyBMMjAuODA3NzE2NSwxOS40NjU5OTk2IEMyMS4zNzk2NDM3LDIwLjA2NDEyNzMgMjEuMzU4NDA0MSwyMS4wMTI2NDQzIDIwLjc2MDI3NjQsMjEuNTg0NTcxNSBDMjAuMTYyMTQ4NywyMi4xNTY0OTg4IDE5LjIxMzYzMTcsMjIuMTM1MjU5MiAxOC42NDE3MDQ0LDIxLjUzNzEzMTUgTDEzLjg3OTc0MzcsMTYuNTU3MDIxNiBDMTMuODQ3MzcxNCwxNi41MjMxNjYzIDEzLjgxNjg5OTUsMTYuNDg4MTg4NSAxMy43ODgzMjUxLDE2LjQ1MjIxOTkgWiBNOS43ODIyMzQ0NiwyLjc0OTIxNjA2IEw5Ljc4MjIzNDQ2LDMuNDk4NDMyMTIgQzYuMzExMDQ5OTgsMy40OTg0MzIxMiAzLjQ5ODQzMjEyLDYuMzEwMzkzOSAzLjQ5ODQzMjEyLDkuNzgwNTM4MTIgQzMuNDk4NDMyMTIsMTMuMjUwMjQ5NCA2LjMxMTI5NTU2LDE2LjA2MjY0NDEgOS43ODIyMzQ0NiwxNi4wNjI2NDQxIEMxMy4yNTI0NDgsMTYuMDYyNjQ0MSAxNi4wNjU2OTc1LDEzLjI0OTk1NyAxNi4wNjU2OTc1LDkuNzgwNTM4MTIgQzE2LjA2NTY5NzUsNi4zMTA2ODYyMyAxMy4yNTI2OTM1LDMuNDk4NDMyMTIgOS43ODIyMzQ0NiwzLjQ5ODQzMjEyIEw5Ljc4MjIzNDQ2LDIuNzQ5MjE2MDYgWiIvPgogIDwvZGVmcz4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+CiAgICA8dXNlIGZpbGw9IiM2Y2FjZTQiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI3NlYXJjaC1hIi8+CiAgPC9nPgo8L3N2Zz4K");
  }
}
