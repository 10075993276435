.video-grid-block__video-overlay {
  &:hover svg,
  &:focus svg,
  .video-grid-block:hover & svg {
    color: rgba($drexel-gold, 0.7);
  }
}

.video-grid-block__video-icon {
  svg {
    color: rgba($base-background-color, 0.7);
  }
}

.video-grid-block__play-overlay {
  border-bottom-color: transparent;
}

.video-grid-block:hover .video-grid-block__play-overlay,
.video-grid-block__play-overlay--active {
  border-color: $drexel-gold;
}

.video-grid-block__play-overlay--active {
  background-color: rgba(0, 0, 0, 0.5);
  color: $white;
}

.video-grid-block__title {
  color: $drexel-grey-headline;

  button {
    border-bottom-color: transparent;
  }
}

.video-grid-block:hover .video-grid-block__title button,
.video-grid-block__title--active button,
.video-grid-block__title button:focus {
  border-bottom-color: $grey-600;
}

//Nicer version of the underline, for browsers that support it:
@supports (text-underline-offset: 0.1em) {
  .video-grid-block__title button {
    text-decoration-color: rgba($grey-600, 0);
  }

  .video-grid-block__title--active button,
  .video-grid-block__title button:focus,
  .video-grid-block:hover .video-grid-block__title button {
    text-decoration-color: $grey-600;
  }
}

.video-grid-block__expanding-panel {
  background-color: $grey-100;
}

.video-grid-block__video-close {
  background-color: transparent;

  svg {
    color: $grey-400;
  }

  &:hover,
  &:focus {
    svg {
      color: $black;
    }
  }
}
