.footer-social {
  border-bottom-color: rgba($white, 0.3);
}

.footer-social__label {
  color: $drexel-blue-pale;

  .subfooter & {
    color: $drexel-navy;
  }
}

.footer-social__links a {
  .subfooter & {
    color: $drexel-navy;

    &:hover,
    &:focus {
      color: $drexel-blue;
    }
  }
}
