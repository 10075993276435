// Slate Forms base theming

.form-slate {
  // Nesting in this case is necessary, as we're overriding 3rd party stylesheets

  .form_button_submit {
    border-color: $drexel-gold;
    color: $black;
    position: relative;
    z-index: $stack-internal;

    &:before {
      background: linear-gradient(
        135deg,
        $drexel-gold 0%,
        $drexel-orange-light 100%
      );
      content: "";
      display: block;
      height: calc(100% + 4px); /* Allow for border */
      position: absolute;
      top: -2px; /* Allow for border */
      left: -2px; /* Allow for border */
      opacity: 0;
      width: calc(100% + 4px); /* Allow for border */
      z-index: $stack-back;
      transition: opacity 0.3s;
    }

    &:active,
    &:hover,
    &:focus {
      &:before {
        opacity: 1;
      }
    }
  }
}
