// Adding some specificity to make sure we're targeting Sitecore Forms

form[action*="formbuilder?"] {
  // Submit button
  [type="submit"] {
    @extend .btn--primary;

    &:active,
    &:hover,
    &:focus {
      border-color: $drexel-gold;
    }
  }

  // Some overrides for input type=submit
  input[type="submit"] {
    &:active,
    &:hover,
    &:focus {
      background-color: $drexel-gold;
    }
  }
}
