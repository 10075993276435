@include create-gradient-variants(
  $core-theme-gradient-colors,
  "expert-promo",
  0.7,
  -45deg,
  "true"
);
@include create-gradient-variants(
  $core-theme-gradient-colors,
  "expert-promo__image-wrapper",
  0.7,
  -45deg,
  "true"
);
@include create-gradient-variants(
  $core-theme-gradient-colors,
  "expert-promo__outer-image-wrapper",
  1,
  -45deg,
  "true"
);

.expert-promo {
  a:hover,
  a:focus {
    color: $base-link-color;
    border-bottom-color: currentColor;
  }

  a.expert-promo__image-wrapper {
    &:hover,
    &:focus {
      border-bottom-color: $drexel-gold;
    }
  }
}

.expert-promo__links {
  &:before {
    background-color: $drexel-gold;
  }
}

.expert-promo__area {
  color: $drexel-grey-light;
}

.expert-promo__address {
  &:before {
    background-color: $drexel-gold;
  }
}
