a.media-promo__tag {
  background-color: $drexel-gold;
  color: $drexel-grey-headline;

  &:hover,
  &:focus {
    background-color: $drexel-navy;
    color: $white;
  }
}

.media-promo__icon {
  border-color: $drexel-gold;

  .clickable-card--whole-card:hover &,
  &:hover {
    border-color: $drexel-navy;
  }
}

.media-promo__video-link {
  button {
    color: $drexel-navy;

    &:hover,
    &:focus {
      .media-promo__button-text {
        border-bottom-color: $drexel-navy;
      }

      .media-promo__play {
        border-color: $drexel-blue;
      }

      .media-promo__play:after,
      .media-promo__play:before,
      .media-promo__play .media-promo__line {
        background-color: $drexel-blue;
      }

      .media-promo__play .media-promo__triangle {
        border-color: transparent transparent transparent $drexel-blue;
      }
    }
  }

  svg {
    color: $drexel-blue-light;
  }
}

/* Animated Play Button */
.media-promo__play {
  border-color: $drexel-blue-light;
}

.media-promo__play:after,
.media-promo__play:before,
.media-promo__play .media-promo__line {
  background-color: $drexel-blue-light;
}

.media-promo__play .media-promo__triangle {
  border-color: transparent transparent transparent $drexel-blue-light;
}
