.breadcrumbs {
  a {
    color: $black;

    &:hover,
    &:focus {
      color: $drexel-blue;
    }
  }
}

.breadcrumbs__item {
  &:after {
    color: $drexel-gold;
  }
}
