.search-listing__date-calendar-picker {
  color: $drexel-grey-medium;

  input {
    color: $drexel-grey-medium;
  }
}

.search-listing__icon {
  color: $drexel-blue-light;
}

.search-listing__rule {
  &:after {
    background-color: $drexel-gold;
  }
}

.search-listing__date-toggle {
  color: $drexel-blue-light;
}

.search-listing {
  .search-box__input {
    &.is-dirty label,
    button:focus ~ label,
    input:focus ~ label {
      color: $drexel-grey-text;
    }
  }
}
