.related-event {
  background-color: $drexel-grey-pale;
  position: relative;
  z-index: $stack-internal;

  &:before {
    background: linear-gradient(
      135deg,
      $drexel-blue 0%,
      $drexel-blue-light 100%
    );
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: $stack-back;
    transition: opacity $standard-transition;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  &.clickable-card--whole-card:hover {
    :not(.layout-columns) > & {
      border: none;
    }

    color: $white;

    &:before {
      opacity: 1;
    }
  }

  &.related-event--inverse {
    background-color: $white;

    // Only add hover styles if it's a clickable card and JS initialized
    &.clickable-card--whole-card:hover {
      background: linear-gradient(
          135deg,
          $drexel-blue 0%,
          $drexel-blue-light 100%
        )
        padding-box;
      color: $white;
    }
  }
}

.related-event__date-card {
  color: $drexel-grey-light;

  &:before {
    background-color: $drexel-gold;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    color: $white;
  }
}

.related-events__full {
  @include font(stevie, regular);
  color: $white;
  font-size: rem(13);
  height: rem(90);
  letter-spacing: 0.7px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  text-transform: uppercase;
  width: rem(90);

  &:before {
    border: rem(45) solid transparent;
    border-top: rem(45) solid $drexel-grey-light;
    border-right: rem(45) solid $drexel-grey-light;
    box-sizing: border-box;
    content: "";
    display: block;
    height: rem(90);
    position: absolute;
    top: 0;
    left: 0;
    width: rem(90);
  }
}

.related-events__full-inner {
  position: absolute;
  right: rem(11);
  top: rem(11);
}
