.color-block-cta {
  background-color: rgba($drexel-navy, 0.8);

  &.color-block-cta--light {
    background-color: $drexel-grey-pale;
    color: $drexel-grey-text;

    .color-block-cta__title {
      color: $drexel-grey-headline;
    }
  }
}
