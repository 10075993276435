.primary-nav {
}

.primary-nav__top-level-item {
  border-top-color: rgba($white, 0.3);

  &:focus {
    color: $drexel-gold;
  }

  &.primary-nav__child-trigger.is-open {
    &:before {
      border-left-color: $drexel-navy;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    .is-active {
      &:after {
        background-color: $drexel-gold;
      }
    }
  }
}

.primary-nav__sublevel {
  @media (min-width: $bp-min-small-tablet) {
    background-color: $drexel-navy;
  }

  a {
    @media (min-width: $bp-min-small-tablet) {
      color: $white;
    }

    &:hover,
    &:focus {
      @media (min-width: $bp-min-small-tablet) {
        color: $drexel-blue-light;
      }
    }
  }
}

.primary-nav__sublevel-item {
  border-top-color: rgba($white, 0.3);

  /* This is ugly, but necessary to deal with the magic shrinking menu */
  .primary-nav__sublevel-item[style*="none"] + & {
    border-top-color: transparent;
  }
}

.primary-nav__mobile-utility-nav {
  a {
    color: $drexel-blue-light;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.primary-nav__plus-sign {
  color: $drexel-blue-light;
}

.primary-nav__top-level-item:hover,
.primary-nav__top-level-item:focus {
  color: $drexel-gold;
}
