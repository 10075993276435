.event-meta {
  .icon-link__icon {
    color: $drexel-blue-light;
  }

  .icon-link:hover,
  .icon-link:focus {
    color: $drexel-navy;

    .icon-link__icon {
      color: $drexel-blue;
    }
  }
}
