.link-list__item {

  a {
    color: $drexel-blue;

    &:hover,
    &:focus {
      span {
        border-bottom-color: $drexel-navy;
      }
    }
  }

  svg {
    color: $drexel-blue;
  }
}
