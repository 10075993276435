input,
textarea {
  border-color: $black;
  color: $drexel-grey-medium;
}

// Have to separate :has rules or Firefox will bail on all

// All browsers
input[type="checkbox"]:checked + label {
  &:before {
    border-color: $drexel-blue-light;
  }
  &:after {
    border-color: $drexel-blue-light;
  }
}

// Those that understand :has
label:has(input[type="checkbox"]:checked) {
  &:before {
    border-color: $drexel-blue-light;
  }
  &:after {
    border-color: $drexel-blue-light;
  }
}

// All browsers
input[type="radio"]:checked + label {
  &:before {
    border-color: $drexel-blue-light;
  }
  &:after {
    background-color: $drexel-blue-light;
  }
}

// Those that understand :has
label:has(input[type="radio"]:checked) {
  &:before {
    border-color: $drexel-blue-light;
  }
  &:after {
    background-color: $drexel-blue-light;
  }
}

select {
  border-color: $black;
  color: $drexel-grey-medium;
}
