.container--navyband .promo-grid * {
  color: $white;

  a:hover,
  a:focus {
    border-bottom-color: rgba($white, 0.8);
  }

  .media-promo {
    a:hover,
    a:focus,
    &.clickable-card--whole-card:hover .media-promo__title a {
      border-bottom-color: $drexel-gold;
    }
  }

  @media print {
    color: $black;
  }
}
